<template>
  <div id="start-screen">
    <div class="min-h-screen flex flex-col justify-between px-6 py-5 2xs:py-8 max-w-2xl mx-auto">
      <div
        class="flex flex-col items-center justify-center text-center font-bold text-text-secondary"
      >
        <BrandLogoIcon class="h-18 xs:h-32 sm:h-44 mb-1" />

        <BrandWhiteIcon class="h-11 md:h-20 mt-12 mb-8" />

        <p class="text-sm 2xs:text-base sm:text-lg pt-1">A new way to get financial support</p>
      </div>
      <div class="flex flex-col text-center mb-14">
        <router-link
          class="bg-btn-bg-secondary my-3 py-4 rounded text-sm xs:text-base font-bold text-green"
          :to="{ name: 'Signup' }"
          >Start a Fundraiser</router-link
        >
        <button
          class="bg-btn-bg-alternate-1 text-btn-text-alternate-1 my-3 py-4 rounded text-sm xs:text-base font-bold focus:outline-none"
          @click="isLoginOpen = true"
        >
          Sign in
        </button>
        <router-link
          class="text-text-secondary font-bold text-sm mt-5"
          :to="{ name: 'Feeds' }"
          >Browse Fundraisers</router-link
        >
      </div>
      <p class="text-center text-xs xs:text-sm">
        By continuing to use Blessmi, you agree with the Blessmi
        <button
          class="font-semibold text-btn-text-alternate-1 focus:outline-none"
          @click="isTermsOpen = true"
        >
          terms
        </button>
        and
        <button
          class="font-semibold text-btn-text-alternate-1 focus:outline-none"
          @click="isPrivacyOpen = true"
        >
          privacy policy
        </button>.
      </p>
    </div>
  </div>
</template>

<script>
import { toRefs } from 'vue';
import useSideMenuState from '@/cmp-functions/useSideMenuState.js';

export default {
  name: 'StartScreen',

  setup() {
    const { state } = useSideMenuState();

    return { ...toRefs(state) };
  },
};
</script>

<style lang="scss" scoped>
#start-screen {
  background-color: #85B739;
}

.text-green {
  color: #648F23;
}
</style>
